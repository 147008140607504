<template>
    <div>
        <el-card header="筛选查询" :body-style="{padding: '20px 20px 0 20px'}" v-if="searchForm">
            <el-form :inline="true" :model="searchValue" size="mini">
                <el-form-item v-for="(item,index) in searchForm" v-if="!item.hidden"
                              :label="(item.type!=='submit'&&item.type!=='reset')?item.label:''"
                              :prop="item.prop" :key="index">
                    <el-input v-if="item.type==='input'" v-model="searchValue[item.prop]"
                              :style="'width: '+item.width+'px'" clearable
                              :placeholder="item.placeholder||item.label" @input="inputChange"/>
                    <el-select v-else-if="item.type==='select'" clearable v-model="searchValue[item.prop]"
                               :placeholder="item.placeholder||item.label" :style="'width: '+item.width+'px'"
                               @change="onSelectChange" filterable>
                        <el-option v-for="(optItem,index) in item.list" :key="index" :label="optItem.name"
                                   :value="optItem.id"></el-option>
                    </el-select>
                    <el-date-picker
                            :style="'width: '+item.width+'px'"
                            v-else-if="item.type==='daterange'"
                            v-model="dateRang"
                            type="daterange"
                            unlink-panels
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="onRangChange"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                    <el-date-picker
                            :style="'width: '+item.width+'px'"
                            v-else-if="item.type==='date'"
                            v-model="searchValue[item.prop]"
                            :placeholder="item.placeholder||item.label"
                            :type="item.dateType"
                            align="center"
                            unlink-panels
                            @change="onDateChange"
                            value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <el-radio-group @change="radioChange" v-else-if="item.type==='radio'"
                                    v-model="searchValue[item.prop]">
                        <el-radio-button v-for="(radio,index) in item.list" :key="index" :label="radio.id">
                            {{radio.name}}
                        </el-radio-button>
                    </el-radio-group>
                    <el-button v-else-if="item.type==='submit'" type="primary" @click="customGetData()">
                        {{item.label||'查询'}}
                    </el-button>
                    <el-button v-else-if="item.type==='reset'" type="primary" @click="reset">
                        {{item.label||'重置'}}
                    </el-button>
                </el-form-item>
                <el-form-item v-if="searchForm.length===3" style="visibility: hidden">
                    <el-input></el-input>
                </el-form-item>
            </el-form>
        </el-card>
        <br v-if="!hideBr&&searchForm"/>
        <el-card :body-style="{padding:0}" style="position: relative" v-if="tableConfig">
            <template v-slot:header v-if="tableConfig.header">
                <span>{{tableConfig.header}}</span>
                <el-button-group style="position: absolute;right: 5px;top:5px">
                    <el-button v-for="(button,index) in tableConfig.headerButton" :key="index" v-if="!button.hidden"
                               :type="buttonType[index%buttonType.length]" size="mini"
                               @click="/*button.name==='导出'?exportExcel():*/button.click()">{{button.name}}
                    </el-button>
                </el-button-group>
            </template>
            <el-table id="table" :data="tableConfig.data" :border="!tableConfig.noBorder" size="mini"
                      :stripe="tableConfig.stripe" @selection-change="handleSelectionChange"
                      @select="handleSelect" ref="table"
                      :cell-style="tableConfig.cellStyle" :span-method="tableConfig.spanMethod"
                      :default-expand-all="tableConfig.expandAll"
                      :summary-method="summaries" :show-summary="tableConfig.needSum" :row-key="tableConfig.rowKey"
                      @row-click="rowClick">
                <el-table-column
                        v-if="tableConfig.needSelect"
                        type="selection"
                        align="center"
                        width="40">
                </el-table-column>
                <el-table-column
                        v-if="tableConfig.needIndex"
                        type="index"
                        align="center"
                        width="40">
                </el-table-column>
                <!--:show-overflow-tooltip="true||item.tooltip"-->
                <el-table-column
                        v-for="(item,index) in tableConfig.column"
                        :key="index"
                        :show-overflow-tooltip="true"
                        :prop="item.prop"
                        :label="item.label"
                        :align="item.align||'left'"
                        :width="item.width"
                        :min-width="item.minWidth">
                    <template v-slot="scope">
                        <slot :row="scope.row" :column="scope.column">
                            <span v-if="item.label==='状态'" :style="scope.row.color?('color:'+scope.row.color):(scope.row.status==1?'color:#67C23A':'color:#F56C6C')">
                                {{item.render?item.render(scope.row[item.prop],scope.row):scope.row[item.prop]}}
                            </span>
                            <span v-else>
                                 {{item.render?item.render(scope.row[item.prop],scope.row):scope.row[item.prop]}}
                            </span>
                        </slot>
                    </template>
                </el-table-column>
                <el-table-column
                        v-if="tableConfig.action"
                        prop="action"
                        label="操作"
                        :width="tableConfig.actionWidth||100"
                        align="center">
                    <template v-slot="scope">
                        <el-button v-for="(button,index) in tableConfig.action" type="text" size="mini"
                                   v-if="initHidden(button.hidden,scope.row)"
                                   @click="button.click(scope.row)" :key="index">{{button.name}}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    v-if="tableConfig.pageConfig||(tableConfig.pageConfig&&!tableConfig.pageConfig.notPage)"
                    style="float: right;margin:5px 10px"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="parseInt(tableConfig.pageConfig.pageNum)"
                    :page-sizes="tableConfig.pageSizes||[10,20,30,40,50]"
                    :page-size="parseInt(tableConfig.pageConfig.pageSize)"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableConfig.pageConfig.total">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>

    export default {
        name: "baseTable",
        props: {
            hideBr: {
                type: Boolean,
                default: false,
            },
            searchForm: Array,
            searchValue: Object,
            tableConfig: {
                headerButton: Array,
                needSelect: Boolean,
                needIndex: Boolean,
                needSum: Boolean,
                cellStyle: Function,
                actionWidth: Number,
                data: Array,
                column: Array,
                action: Array,
                pageConfig: {
                    pageSize: Number,
                    pageNum: Number,
                    total: Number,
                },
                getMultipleSelection: Function
            },
            getData: Function
        },
        data() {
            return {
                buttonType: ['primary', 'success', 'warning', 'danger', 'info', ''],
                dateRang: [],
                multipleSelection: [],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
            }
        },
        methods: {
            initHidden(hidden, row) {
                if (hidden) {
                    if (hidden instanceof Boolean) {
                        return !hidden
                    } else if (hidden instanceof Function) {
                        return !hidden(row)
                    }
                } else {
                    return true
                }
            },
            reset() {
                this.dateRang = []
                for (let key in this.searchValue) {
                    if (this.searchValue.hasOwnProperty(key)) {
                        delete this.searchValue[key]
                    }
                }
                this.customRefresh()
            },
            inputChange(e) {
                this.customRefresh()
            },
            onSelectChange(e) {
                this.customRefresh()
            },
            handleSizeChange(pageSize) {
                this.$router.replace({
                    path: this.$route.path,
                    query: {...this.$route.query, pageSize}
                })
                this.tableConfig.pageConfig.pageSize = pageSize
                this.customGetData()
            },
            handleCurrentChange(pageNum) {
                this.$router.replace({
                    path: this.$route.path,
                    query: {...this.$route.query, pageNum}
                })
                this.tableConfig.pageConfig.pageNum = pageNum
                this.customGetData()
            },
            onRangChange(value) {
                this.searchValue.startDate = value ? value[0] /*+ ' 00:00:00'*/ : ''
                this.searchValue.endDate = value ? value[1] /*+ ' 23:59:59'*/ : ''
                this.customRefresh()
            },
            onDateChange(value) {
                this.searchValue.startDate = value
                this.searchValue.endDate = this.moment(value, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')
                this.customRefresh()
            },
            radioChange() {
                this.customRefresh()
            },
            summaries(param) {
                if (this.tableConfig.needSum) {
                    let list = this.tableConfig.column.map(item => {
                        if (item.needSum) {
                            return item.prop
                        }
                    })
                    const {columns, data} = param;
                    const sums = [];
                    columns.forEach((column, index) => {
                        if (index === 0) {
                            sums[index] = '总计';
                            return;
                        } else if (list.indexOf(column.property) === -1) {
                            return;
                        }
                        const values = data.map(item => Number(item[column.property]));
                        if (!values.every(value => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return prev + curr;
                                } else {
                                    return prev;
                                }
                            }, 0);
                        } else {
                            sums[index] = '';
                        }
                    });
                    return sums;
                }
            },
            customGetData() {
                this.getData ? this.getData() : this.$parent.getData()
            },
            customRefresh(){
                this.$parent.refresh()
            },
            handleSelect(selection, row) {

            },
            handleSelectionChange(val) {
                this.tableConfig.getMultipleSelection(val)
            },
            rowClick(row, column, event) {
                this.tableConfig.rowClick && this.tableConfig.rowClick(row, column, event)
                if (this.tableConfig.needSelect && column.property !== 'num') {
                    this.$refs.table.toggleRowSelection(row);
                }
            }
        }
    }
</script>

<style scoped>
    /*card阴影加重*/
    .el-card.is-always-shadow {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);
    }
</style>